<template>
  <div class="container realtime" ref="pageContainer" v-title :data-title="$t('i18n.' + title)">
    <el-form :style="isMobile ? 'margin-top:20px;' : ''" :inline="true" :model="formInline" class="demo-form-inline"
      label-position="left" @submit.native.prevent>
      <el-form-item>
        <el-input autocomplete="off" v-model="formInline.keyword" :placeholder="$t('i18n.noAddressName')"
          @keyup.enter="setParam({})">
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" class="searchBtn" @click="setParam({})">
          {{ $t("i18n.search") }}
        </el-button>
      </el-form-item>
      <el-form-item v-if="brandIndex !== ''">
        <el-tag closable :disable-transitions="false" @close="
    brandIndex = '';
  setParam({ elevatorBrandId: '' });
  ">
          {{ $t("i18n.elevatorBrand") }}：{{ brandData[brandIndex].name }}
        </el-tag>
      </el-form-item>
      <el-form-item v-if="typeIndex !== ''">
        <el-tag closable :disable-transitions="false" @close="
    typeIndex = '';
  setParam({ type: '' });
  ">
          {{ $t("i18n.elevatorType") }}：
          {{ $t("i18n." + typeData[typeIndex].name) }}
        </el-tag>
      </el-form-item>
      <el-form-item v-if="statusIndex !== ''">
        <el-tag closable :disable-transitions="false" @close="
    statusIndex = '';
  changeStatus('');
  ">
          {{ $t("i18n.elevatorStatus") }}：
          {{ $t("i18n." + statusData[statusIndex].name) }}
        </el-tag>
      </el-form-item>

      <el-form-item v-if="moduleIndex !== ''">
        <el-tag closable :disable-transitions="false" @close="
    moduleIndex = '';
  changeModule('0');
  ">
          {{ $t("i18n.moduleType") }}：
          {{ $t("i18n." + moduleData[(moduleIndex / 10) - 1].name) }}
        </el-tag>
      </el-form-item>

      <el-form-item v-if="formInline.homeLift === '0'">
        <el-tag closable :disable-transitions="false" @close="setParam({ homeLift: '' })">
          {{ $t("i18n.elevatorType") }}：{{ $t("i18n.homeLift1") }}
        </el-tag>
      </el-form-item>
    </el-form>
    <el-radio-group v-if="!isMobile" v-model="statusIndex" @change="changeStatus">
      <el-radio-button label="0">
        <span>{{ $t("i18n.all") }}：</span>
        <span class="num">{{ typeCount.allElevatorCount }}</span>
      </el-radio-button>
      <el-radio-button label="4">
        <span>{{ $t("i18n.onlineNum") }}：</span>
        <span class="num">{{ typeCount.onlineElevatorCount }}</span>
      </el-radio-button>
      <el-radio-button label="2">
        <span>{{ $t("i18n.todayFault") }}：</span>
        <span class="num">{{ typeCount.faultElevatorCount }}</span>

      </el-radio-button>
    </el-radio-group>
    <div v-if="!isMobile" class="showSelect" @click="
    isShow = isShow == 'putAwayFilter' ? 'displayFilter' : 'putAwayFilter'
    ">
      {{ $t("i18n." + isShow) }}
      <i class="el-icon-arrow-down"></i>
    </div>
    <div class="m-t" v-show="isShow == 'putAwayFilter'">
      <div class="selectList" :style="{ height: isBrandUp ? '42px' : 'auto' }">
        <label>{{ $t("i18n.elevatorBrand") }}：</label>
        <div>
          <span :class="{ active: brandIndex === index }" v-for="(item, index) in brandData" :key="item.id" @click="
    brandIndex = index;
  setParam({ elevatorBrandId: item.id });
  ">
            {{ item.name }}
          </span>
        </div>
        <a v-if="isBrandUp" href="javascript:void(0);" @click="isBrandUp = !isBrandUp">
          {{ $t("i18n.more") }}
          <i class="el-icon-arrow-down"></i>
        </a>
        <a v-else href="javascript:void(0);" @click="isBrandUp = !isBrandUp">
          {{ $t("i18n.putAway") }}
          <i class="el-icon-arrow-up"></i>
        </a>
      </div>
      <div class="selectList">
        <label>{{ $t("i18n.elevatorType") }}：</label>
        <div>
          <span :class="{ active: typeIndex === index }" v-for="(item, index) in typeData" :key="item.value" @click="
    typeIndex = index;
  setParam({ type: item.value });
  ">{{ $t("i18n." + item.name) }}
          </span>
          <span :class="{ active: formInline.homeLift === '0' }" @click="setParam({ homeLift: '0' })">{{
    $t("i18n.homeLift1") }}
          </span>
        </div>
      </div>
      <div class="selectList">
        <label>{{ $t("i18n.elevatorStatus") }}：</label>
        <div>
          <span :class="{ active: statusIndex === item.value }" v-for="item in statusData" :key="item.value" @click="
    statusIndex = item.value;
  changeStatus(item.value);
  ">{{ $t("i18n." + item.name) }}
          </span>
        </div>
      </div>
      <div class="selectList">
        <label>{{ $t("i18n.moduleType") }}：</label>
        <div>
          <span :class="{ active: moduleIndex === item.value }" v-for="item in moduleData" :key="item.value" @click="
    moduleIndex = item.value;
  changeModule(item.value);
  ">{{ $t("i18n." + item.name) }}
          </span>
        </div>
      </div>
      <div class="selectList m-t p0" style="overflow: visible">
        <span class="rank" @click="changeStatus(0)">
          {{ $t("i18n.all") }}
        </span>
        <span class="rank" :class="{ active: formInline.sortOrder }" @click="changeRank">
          {{ $t("i18n.equipmentNo") }}
          <i v-if="formInline.sortOrder == 'asc'" class="el-icon-top"></i>
          <i v-else class="el-icon-bottom"></i>
        </span>
        <label>{{ $t("i18n.location") }}：</label>
        <el-cascader @change="getSelectCity" :placeholder="$t('i18n.pleaseSelect')" :options="province"
          :props="{ checkStrictly: true, expandTrigger: 'hover' }" clearable filterable></el-cascader>
        <div class="input-hover">
          <input type="text" v-model="formInline.minEquipmentNumber" :placeholder="$t('i18n.equipmentNo')" />
          <span>-</span>
          <input type="text" v-model="formInline.maxEquipmentNumber" :placeholder="$t('i18n.equipmentNo')" />
          <button @click="
    setParam({
      minEquipmentNumber: formInline.minEquipmentNumber,
      maxEquipmentNumber: formInline.maxEquipmentNumber,
    })
    ">
            {{ $t("i18n.confirm") }}
          </button>
        </div>
        <div class="input-hover">
          <input type="text" v-model="formInline.minNumber" :placeholder="$t('i18n.elevatorNo')" />
          <span>-</span>
          <input type="text" v-model="formInline.maxNumber" :placeholder="$t('i18n.elevatorNo')" />
          <button @click="
    setParam({
      minNumber: formInline.minNumber,
      maxNumber: formInline.maxNumber,
    })
    ">
            {{ $t("i18n.confirm") }}
          </button>
        </div>
        <div class="icon-group">
          <i :class="{ active: isView }" @click="isView = !isView" class="el-icon-menu"></i>
          <i :class="{ active: !isView }" @click="isView = !isView" class="el-icon-s-fold"></i>
        </div>
      </div>
    </div>
    <div style="margin-top=5px"></div>
    <div class="eleList" v-show="isView">
      <div v-if="eleData.length == 0">{{ $t("i18n.noRelevantData") }}</div>
      <div class="items" v-for="(item, index) in eleData" :key="item.id">
        <div class="flex">
          <img v-if="item.websocketType && item.websocketType.includes('60')" class="creamer" :src="require('../../../public/images/creamer.jpg')
    " />
          <div class="left">
            <span v-if="item.elevatorVariety != '30'" class="showFloor">
              {{ item.floor }}
            </span>
            <img v-if="item.elevatorVariety == '30'" @click="enterDetail(item.id)" class="automatic"
              src="../../../public/images/automatic.png" />
            <img v-else-if="!item.runImg" @click="enterDetail(item.id)" class="elevatorImg"
              src="../../../public/images/elevator_closed02.png" />
            <img v-else class="elevatorImg" @click="enterDetail(item.id)"
              :src="require('../../../public/images/' + item.runImg)" />
          </div>
          <div class="right">
            <img v-if="item.signalIntensity" class="signalImg" :src="require('../../../public/images/' +
    item.signalIntensity +
    '.png')
    " />
            <img v-else class="signalImg" src="../../../public/images/icon_mobile_0.png" />
            <span class="favImg" :class="{ active: item.favorite == 1 }" @click="favFuncEvent(item, index)"></span>
            <div class="up-area">
              <img v-if="item.flow == '011' || item.flow == '010'" class="direction-up"
                src="../../assets/flowupgreen.png" />
            </div>
            <img v-if="item.maintenanceStatus == 20" class="elevator-rImg" src="../../assets/recondition.png" />
            <img v-else-if="item.faultStatus == 20 && item.isHandled == 20" class="elevator-rImg"
              src="../../assets/faultHandling2.png" />
            <img v-else-if="item.faultStatus == 20 && item.isHandled != 20" class="elevator-rImg"
              src="../../assets/malfunction.png" />
            <div v-else-if="item.floor &&
    item.floor != '通讯异常' &&
    item.elevatorVariety != '30'
    " class="floor-ind">
              {{ item.floor }}
            </div>
            <div class="down-area">
              <img v-if="item.flow == '101' || item.flow == '100'" class="direction-down"
                src="../../assets/flowdowngreen.png" />
            </div>
          </div>
        </div>
        <div class="eleInfo">
          <p class="p-num">{{ item.number }}</p>
          <p class="p-addr">{{ item.alias }}</p>
          <div v-if="!isMobile">
            <button class="send" v-show="item.sendYes == 'Y'" @click="enterPage(item, true)">
              {{ $t("i18n.sendOrder") }}
            </button>
            <button class="view" v-show="item.checkYes == 'Y'" @click="enterPage(item)">
              {{ $t("i18n.viewOrder") }}
            </button>
          </div>
        </div>
      </div>
      <template v-if="eleData.length % rows > 0">
        <div class="items noborder" v-for="item in rows - (eleData.length % rows)" :key="item"></div>
      </template>
    </div>
    <el-table v-show="!isView" :data="eleData" class="m-t" height="500" style="width: 100%">
      <el-table-column type="index" label=" "></el-table-column>
      <el-table-column width="120" prop="number" :label="$t('i18n.equipmentNo')"></el-table-column>
      <el-table-column prop="alias" :label="$t('i18n.projectName')" width="280"></el-table-column>
      <el-table-column prop="address" :label="$t('i18n.address')" width="350"></el-table-column>
      <el-table-column align="center" :label="$t('i18n.runningState')">
        <template #default="scope">
          <span v-if="scope.row.status == 10">{{ $t("i18n.online") }}</span>
          <span v-else>{{ $t("i18n.offline") }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" :label="$t('i18n.malfunctionState')">
        <template #default="scope">
          <span v-if="scope.row.faultStatus == 20">
            {{ $t("i18n.malfunction") }}
          </span>
          <span v-else>{{ $t("i18n.normal") }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" :label="$t('i18n.reconditionState')">
        <template #default="scope">
          <span v-if="scope.row.maintenanceStatus == 20">
            {{ $t("i18n.recondition") }}
          </span>
          <span v-else>{{ $t("i18n.normal") }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" :label="$t('i18n.currentFloor')" prop="floor"></el-table-column>
      <el-table-column align="center" :label="$t('i18n.upDown')">
        <template #default="scope">
          <img v-if="scope.row.flow == '010' || scope.row.flow == '011'" src="../../assets/flowupgreen.png" />
          <img v-if="scope.row.flow == '101' || scope.row.flow == '100'" src="../../assets/flowdowngreen.png" />
        </template>
      </el-table-column>
    </el-table>
    <div class="m-t text-center" v-show="total > 0">
      <el-pagination v-if="isMobile" prev-text="上一页" next-text="下一页" pager-count="5"
        @current-change="handleCurrentChange" :current-page="formInline.page" :page-size="formInline.pageSize"
        layout="prev, pager, next" :total="total"></el-pagination>
      <el-pagination v-else @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="formInline.page" :page-sizes="[10, 30, 50, 100]" :page-size="formInline.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </div>
  </div>
</template>

<script>
import socket from "../../../public/js/socket";
import {
  reactive,
  toRefs,
  getCurrentInstance,
  onMounted,
  ref,
  onBeforeUnmount,
  onUnmounted
} from "vue";
import { useRoute, useRouter } from "vue-router";
import store from "../../utils/store";
export default {
  name: "Realtime",
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const pageContainer = ref(null);
    const state = reactive({
      formInline: {
        cityId: "",
        elevatorBrandId: "",
        provinceId: "",
        regionId: "",
        faultStatus: "",
        normalStatus: "10",
        favorite: route.query.flag || 0,
        keyword: "",
        maintenanceStatus: "",
        maxEquipmentNumber: "",
        maxNumber: "",
        minEquipmentNumber: "",
        minNumber: "",
        pageSize: 30,
        page: 1,
        sortOrder: "",
        status: "",
        type: "1",
        homeLift: "", // 区分是否为家用梯
        moduleType: ""
      },
      brandData: [],
      typeData: [
        // 电梯类型
        { name: "notConnected", value: "0" },
        { name: "connected", value: "1" },
        { name: "multiBrandLift", value: "2" },
      ],
      statusData: [
        // 电梯状态
        { name: "all", value: "0" },
        { name: "offline", value: "1" },
        { name: "malfunction", value: "2" },
        { name: "recondition", value: "3" },
        { name: "normal", value: "4" },
      ],
      moduleData: [
        { name: "monarchSystem", value: "10" },
        { name: "bstSystem", value: "20" },
        { name: "generalSystem", value: "30" },
        { name: "escalatorSystem", value: "40" },
        { name: "stepSystem", value: "50" },
        { name: "cameraSystem", value: "60" },
      ],
      brandIndex: "", // 电梯品牌选中
      typeIndex: 1, // 电梯品种选中
      moduleIndex: '',//模块种类
      statusIndex: "4", // 电梯状态选中
      isShow: "displayFilter", // 显示筛选/收起筛选 putAwayFilter
      typeCount: {}, // 全部/故障/正常数目
      isBrandUp: true, // 电梯品牌更多
      isMobile: route.query.mobile,
      title: route.query.flag == 1 ? "myFavorite" : "onlineMonitoring",
      isView: true, // 切换图片/表格试图
      eleData: [],
      rows: 6,
      total: 0,
    });

    const init = () => {
      if (route.query.form == "ziguangyun") {
        // form ziguangyun
        let token = "USER82849715-05c2-448c-bb3d-1d5c93b8b624";
        let userId = "6ce457d270ee0cf2092c0c30cb8b9532";
        localStorage.setItem("token", token);
        localStorage.setItem("userId", userId);
        store.commit("auth_success", token, "");
      }
      if (route.query.token) {
        localStorage.setItem("token", route.query.token);
        store.commit("auth_success", route.query.token, "");
      }
        emit("public_header", !state.isMobile);
      getInit();
    };

    onMounted(() => {
      setRow();
      window.onresize = () => {
        return (() => {
          setRow();
        })();
      };
    });

    onUnmounted(() => {
      console.log(11111111111);
      socket.clearSocket();
    });

    const setRow = () => {
      if (pageContainer.value) {
        let w = pageContainer.value.offsetWidth || 0;
        state.rows =
          parseInt((w - 120) / 202) < 6 ? parseInt((w - 120) / 202) : 6;
      }
    };

    const getInit = () => {
      // 获取电梯品牌
      getEleBrand();
      // 获取省市区
      getCityData();
      // 获取电梯信息
      getEleInfo();
      // 获取全部/故障/正常数目
      getCount();
      // 获取sid 汇川电梯需要
      getSid();
    };

    const getEleBrand = async () => {
      const { data } = await proxy.$api.monitor.brandsAll({ name: "" });
      state.brandData = data;
    };

    const getCityData = () => {
      state.province = proxy.$defined.getProvinceData();
    };

    const getEleInfo = async () => {
      if (state.isMobile) {
        state.formInline.normalStatus = "";
        state.formInline.type = "";
      }
      const { data } = await proxy.$api.monitor.elePages(state.formInline);
      state.total = data.total;
      state.eleData = data.records;
      socket.clearSocket();
      setTimeout(() => {
        socket.set(state.eleData, (ele) => {
          if (Array.isArray(ele)) {
            ele.map((item) => {
              item.favorite = state.eleData[item.index].favorite;
              state.eleData[item.index] = JSON.parse(JSON.stringify(item));
            });
          } else {
            ele.favorite = state.eleData[ele.index].favorite;
            state.eleData[ele.index] = JSON.parse(JSON.stringify(ele));
          }
        });
      }, 500);
    };

    const handleSizeChange = (val) => {
      state.formInline.pageSize = val;
      state.formInline.page = 1;
      getEleInfo();
    };

    const handleCurrentChange = (val) => {
      state.formInline.page = val;
      getEleInfo();
    };

    const getCount = async () => {
      const { data } = await proxy.$api.monitor.eleCounts({
        favorite: state.formInline.favorite,
      });
      state.typeCount = data;
    };

    const getSid = async () => {
      const { data } = await proxy.$api.monitor.eleSid();
      state.sid = data;
    };

    const favFuncEvent = async (item, index) => {
      await proxy.$api.monitor.eleFavorite(item.id);
      let msg = item.favorite == 1 ? "取消收藏成功" : "收藏成功";
      state.eleData[index].favorite = 1 ^ item.favorite;
      proxy.$defined.tip(msg);
    };
    const changeModule = (value) => {
      if (value != 0) {
        state.formInline.moduleType = value
      } else {
        state.formInline.moduleType = null
      }
      getEleInfo();
    }
    const changeStatus = (value) => {
      var obj = {};
      if (value == 0) {
        obj = {
          status: "",
          maintenanceStatus: "",
          faultStatus: "",
          normalStatus: "",
        };
      } else if (value == 1) {
        obj = {
          status: "20",
          maintenanceStatus: "",
          faultStatus: "",
          normalStatus: "",
        };
      } else if (value == 2) {
        obj = {
          status: "",
          maintenanceStatus: "",
          faultStatus: "20",
          normalStatus: "",
        };
      } else if (value == 3) {
        obj = {
          status: "",
          maintenanceStatus: "20",
          faultStatus: "",
          normalStatus: "",
        };
      } else if (value == 4) {
        obj = {
          status: "10",
          maintenanceStatus: "",
          faultStatus: "",
          normalStatus: "10",
        };
      }
      setParam(obj);
    };

    const setParam = (obj) => {
      // 点击设置筛选条件的值
      for (var key in obj) {
        state.formInline[key] = obj[key];
      }
      state.formInline.page = 1;
      getEleInfo();
    };

    const getSelectCity = (arr) => {
      // 选择省市区
      arr = arr instanceof Array ? arr : [];
      var key = ["provinceId", "cityId", "regionId"];
      var obj = {};
      for (var i = 0; i < key.length; i++) {
        obj[key[i]] = arr[i] || "";
      }
      setParam(obj);
    };

    const changeRank = () => {
      // 切换平台档案号排序
      var sort = state.formInline.sortOrder;
      state.formInline.sortOrder = sort == "desc" ? "asc" : "desc";
      handleCurrentChange(1);
    };

    const enterDetail = (id) => {
      // 进入详情页
      let query = { id: id };
      if (state.isMobile) {
        query.mobile = 1;
      }
      if (state.isMobile) {
        router.push({
          path: "/realTime/phoneDetail",
          query: query,
        });
      } else {
        let routeData = router.resolve({
          path: "/realTime/detail",
          query: query,
        });
        window.open(routeData.href, "_blank");
      }

    };

    const enterPage = (item, flag) => {
      var query = { enumber: item.number, id: item.id };
      if (flag) {
        query.billModel = "";
      }
      let routeData = router.resolve({
        path: "/maintenance/workBillBoard",
        query: query,
      });
      window.open(routeData.href, "_blank");
    };

    init();

    return {
      ...toRefs(state),
      changeStatus,
      changeModule,
      getSelectCity,
      setParam,
      changeRank,
      pageContainer,
      handleCurrentChange,
      handleSizeChange,
      enterDetail,
      enterPage,
      favFuncEvent,
    };
  },
};
</script>

<style lang="scss">
@import "../../common/scss/realtime.scss";

.realtime {
  .cascader-menu-list-wrap {
    width: auto !important;
  }

  .area-select.large {
    border: none;
  }
}
</style>
